import React from "react";

//Layout
import HeaderBar from "../layout/HeaderBar";
import { useTranslation } from "react-i18next";

export default function HeaderMenu({logo, SetShowContent}){

    const { t } = useTranslation();

    return(
        <HeaderBar>
            <img style={{ height:"50px", gridRow: '1 / 3', alignSelf: 'center' }} src={logo} />
            <nav style={{flex:"1 1 auto", color:"var(--font-color-green)"}}>
                <ul style={{display:"flex", alignContent:"center", justifyContent:"center", paddingLeft:"0px"}}>                    
                    <li style={{listStyle:"none", cursor:"pointer", 
                    margin:"0px 0px 0px -50px", userSelect:"none", fontSize:"1.2rem", lineHeight:"1.2rem"}}>Avo Journey</li>
                </ul>
            </nav>
            <button
                style={{
                    gridColumn: '1 / 3',
                    width: 'max-content',
                    justifySelf: 'center',
                    padding: '5px',
                    cursor: 'pointer'
                }}
                onClick={() => SetShowContent(false)}
            >
                {t('datapage.regresarapaginainicial')}
            </button>
        </HeaderBar>
    );
}