import { useState, useEffect, createContext } from "react"
import { useTranslation } from 'react-i18next';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {

  const { i18n, t } = useTranslation();
  const [ selectedLanguage, setSelectedLanguage ] = useState("es");
  const [ formatedLanguage, setFormatedLanguage ] = useState("esES");

  const avalibleLanguages = [ "es", "en", "de", "fr" ];

  const getFormatedLanguage = (lang, withStripe) => {

    const stripe = withStripe ? "-" : "";

    if (lang === "es") return `es${stripe}ES`;
    if (lang === "en") return `en${stripe}US`;
    if (lang === "de") return `de${stripe}DE`;
    if (lang === "fr") return `fr${stripe}FR`;
    return `es${stripe}ES`;
  }

  useEffect(() => {
    try {
      const lang = localStorage.getItem("ibisaLang");
      const userLang = navigator?.language?.slice(0, 2);
  
      if (!lang && avalibleLanguages.includes(userLang)) {
        setSelectedLanguage(userLang);
      } else {
        setSelectedLanguage( lang || "en" );
      }
    } catch (err) {}
  }, []);

  useEffect(() => {
    localStorage.setItem('ibisaLang', selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    setFormatedLanguage(getFormatedLanguage(selectedLanguage));
    document.documentElement.lang = selectedLanguage;
  }, [selectedLanguage]);

  return <LanguageContext.Provider
    value={{
      t,
      selectedLanguage,
      setSelectedLanguage,
      formatedLanguage
    }}
  >
    {children}
  </LanguageContext.Provider> 
}