import './App.css';
import { Route, Routes } from "react-router-dom";

//Layout
import MainContainer from './layout/MainContainer';

//Pages
import { HomePage } from './pages/HomePage';

function App() {
  return (
    <MainContainer>      
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/*" element={<HomePage />} />
        <Route path="home" element={<HomePage/>} />
      </Routes>
    </MainContainer>
  );
}

export default App;
