import React, { useContext, useRef, useState } from "react";
import { PieChart, Pie, Sector, Cell, Label, LabelList } from 'recharts';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

//Navigation
import HeaderMenu from "../navigation/HeaderMenu";
import LanguageMenu from "../navigation/LanguageMenu";

//Inputs
import FormBatch from "../input/FormBatch";

//Assets
import LogoIbisa from "../img/logo-ibisa.png";
import BannerAvocado from "../img/banner_avocado_2.jpg";

//Layouts
import CardContainer from "../layout/CardContainer";

import { t } from 'i18next';
import { useTranslation } from "react-i18next";

const data = [
    { name: 'Group A', value: 547 },
    { name: 'Group B', value: 1110 }
  ];

const data1 = [
    { 
        name: 'Consumo', 
        cagua: 547,
        clluvia: 1110,
    },
  ];

  const dataHuella = [
    { 
        name: 'Total', 
        value1: 0.4,
        value2: 296.5,
        value3: 250.1,
    }
  ];

  const dataHuellaPie = [
    { 
        name: 'Total', 
        value: 0.1,
    },
    { 
        name: 'Total', 
        value: 54.2,
    },
    { 
        name: 'Total', 
        value: 45.7,
    }
  ];

  const dataHuellaCPie = [
    { 
        name: 'Total', 
        value: 26,
    },
    { 
        name: 'Total', 
        value: 6,
    },
    { 
        name: 'Total', 
        value:68,
    }
  ];

const COLORS = ['#fea246', '#48919e', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(1)}%`}
    </text>
  );
};

const renderCustomizedLabelC = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

export const HomePage = () => {
    const [batch, SetBatch] = useState(0);
    const [showContent, SetShowContent] = useState(false);
    const { t } = useTranslation();
    const dates = useRef({
        recepDate:null,
        cropDate:null,
        claDate:null,
        dispDate:null,
        portDate:null,
        arriveDate:null,
    });

    function OnClickButtonBatch(tmpValue) {
        console.log("--------Batch", tmpValue);

        if(tmpValue.length >= 3)
        {
            let tmpDays = (tmpValue.slice(0,3) - 500);
            if(tmpDays < 1 || tmpDays > 366) {
                console.log("----Error de batch");
                return;
            }
        }else {
            console.log("----Error de batch");
            return;
        }

        let recepDay = (tmpValue.slice(0,3) - 500) + 1;
        let date = new Date(new Date().getFullYear(), 0);
        let recepDate = new Date(date.setDate(recepDay));
        let cropDate = new Date(date.setDate(date.getDate() - 1));
        let claDate = new Date(date.setDate(date.getDate() + 2));
        let dispDate = null;
        let foundDispatch = false;
        while (!foundDispatch)
        {
            if(date.getDay() === 1)
            {
                dispDate = new Date(date);
                foundDispatch = true;
            }else {
                date = new Date(date.setDate(date.getDate() + 1));
            }
        }
        let portDate = null;
        let foundPort = false;
        while (!foundPort)
        {
            if(date.getDay() === 4)
            {
                portDate = new Date(date);
                foundPort = true;
            }else {
                date = new Date(date.setDate(date.getDate() + 1));
            }
        }

        let arriveDate = new Date(date.setDate(date.getDate() + 12));
        //cropDate.setDate(cropDate.setDate(recepDay) - 1)
        console.log('receptionDate: ' + recepDate);;
        console.log('cropDate: ' + cropDate);
        console.log('claDate: ' + claDate);
        console.log('dispDate: ' + dispDate);
        console.log('portDate: ' + portDate);
        console.log('arriveDate: ' + arriveDate);

        dates.current = {
            recepDate: recepDate.getDate()+"."+(recepDate.getMonth()+1)+"."+recepDate.getFullYear(),
            cropDate:cropDate.getDate()+"."+(cropDate.getMonth()+1)+"."+cropDate.getFullYear(),
            claDate: claDate.getDate()+"."+(claDate.getMonth()+1)+"."+claDate.getFullYear(),
            dispDate: dispDate.getDate()+"."+(dispDate.getMonth()+1)+"."+dispDate.getFullYear(),
            portDate: portDate.getDate()+"."+(portDate.getMonth()+1)+"."+portDate.getFullYear(),
            arriveDate: arriveDate.getDate()+"."+(arriveDate.getMonth()+1)+"."+arriveDate.getFullYear(),
        }

        console.log("----------------------",dates.current.cropDate);
        SetShowContent(true);
    };

    return(
        <>
        { 
            showContent === true ? 
            <div>
                <HeaderMenu logo={LogoIbisa} SetShowContent={SetShowContent} />
                <div style={{width:"95%", height:"80px", borderRadius:"10px",  backgroundPosition:"center center",
                backgroundImage:`url(${BannerAvocado})`, backgroundSize:"cover", margin:"10px auto", display:"flex"}}>
                    <p style={{color:"var(--font-color-white)", fontWeight:"500",
                    textShadow:"2px 2px 2px var(--font-color-black)", padding:"5px", margin:"auto",
                    textAlign:"center", fontSize:"1.2rem", backgroundColor: "var(--black-color-50)",
                    borderRadius: "10px"}}>{t('frontpage.elaguacatehassdejardinexoticsresponsableconelmedioambiente')}</p>
                </div>
                <CardContainer>
                    <p style={{width:"95%", margin:"10px auto", color:"var(--font-color-green)"}}>{t('datapage.unaguacatehasssaborysaludparasumesadesdecolombia')}:</p>
                    <table style={{width: "95%", maxWidth:"600px", marginTop:"30px", margin:"10px auto"}}>
                        <thead>
                            <tr>
                                <td>{t('datapage.nombredelafincaproductora')}:</td>
                                <td>Finca Monteloro</td>                                                 
                            </tr>
                            <tr>
                                <td>{t('words.municipio')}:</td>
                                <td>Abejorral</td>                                                     
                            </tr>                           
                        </thead>
                        <tbody>
                            <tr>
                                <td>{t('words.departamento')}:</td> 
                                <td>Antioquia</td>                                
                            </tr>
                            <tr>
                                <td>{t('datapage.alturapromediodelcultivo')}:</td>
                                <td>2100 m</td>   
                            </tr>
                        </tbody>
                    </table>
                </CardContainer>

                <CardContainer>
                    <p style={{width:"95%", margin:"10px auto", color:"var(--font-color-green)"}}>{t('words.etapa')}:</p>
                    <table style={{width: "95%", maxWidth:"600px", marginTop:"30px", margin:"10px auto"}}>
                        <thead>
                            <tr>
                                <td>{t('datapage.cosechadelaguacate')}:</td>
                                <td>{dates.current.cropDate}</td>                                                 
                            </tr>
                            <tr>
                                <td>{t('datapage.clasificacionyempaque')}:</td>
                                <td>{dates.current.claDate}</td>                                                     
                            </tr>                           
                        </thead>
                        <tbody>
                            <tr>
                                <td>{t('datapage.salidadelpuertoencolombia')}:</td> 
                                <td>{dates.current.portDate}</td>                                
                            </tr>
                            <tr>
                                <td>{t('datapage.llegadaalpuertoeuropa')}:</td>  
                                <td>{dates.current.arriveDate}</td>   
                            </tr>
                        </tbody>
                    </table>
                </CardContainer>

                {/****************************************Huella de hídrica */}
                <div style={{width:"calc(100% - 40px)", margin:"0px auto", padding:"20px", 
                        color:"var(--font-color-white)", backgroundColor:"var(--color-green-1)"}}>
                            
                </div>
                <p style={{fontSize:'1.2rem', textAlign:'center', fontWeight:'bold'}}>{t('datapage.huelladehidrica')}</p>
                <CardContainer>
                    <p style={{width:"95%", margin:"10px auto", color:"var(--font-color-green)"}}>{t('datapage.unaguacatehassresponsableconelagua')}:</p>
                    <table style={{width: "95%", maxWidth:"600px", marginTop:"30px", margin:"10px auto"}}>
                        <tbody> 
                            <tr>
                                <td>{t('datapage.huellahidricadeesteaguacate')}:</td>
                                <td>547 {t('datapage.litrosporkgdeaguacate')}</td>                                                 
                            </tr>
                            <tr>
                                <td>{t('datapage.huellahidricapromediomundial')}:</td>
                                <td>1981 {t('datapage.litrosporkgdeaguacate')}</td>                                                     
                            </tr>                           
                        </tbody>
                    </table>
                </CardContainer>

                <div style={{ margin:"auto"}}>
                    <CardContainer>
                        {/*
                        <p style={{width:"calc(100% - 40px)", margin:"0px auto", padding:"20px", 
                        color:"var(--font-color-white)", backgroundColor:"var(--color-green-1)"}}>
                            Desglose de la huella hídrica de este aguacate:
                        </p>*/}

                        <div style={{display:"flex", justifyContent:"center", flexWrap:"wrap", margin:"10px auto",
                            borderRadius:"20px", width:"80%", boxShadow:"0px 0px 5px 2px var(--black-color-10)"}}>
                            <p style={{width:"95%", margin:"0px auto", color:"var(--font-color-black)", padding:"20px",
                            backgroundColor:"var(--white-color-100)", textAlign:"center"}}>{t('datapage.desglosedelahuellahidricadeesteaguacate')}:</p>
                            {/*
                            <BarChart
                                width={500}
                                height={300}
                                data={dataHuella}
                                margin={{
                                    top: 20,
                                    right: 0,
                                    left: 0,
                                    bottom: 30,
                                }}
                                >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="value1" name="H.H Azul" stackId="a" fill="#1a93ff" />
                                <Bar dataKey="value2" name="H.H Verde" stackId="b" fill="#068000" />
                                <Bar dataKey="value3" name="H.H Gris" stackId="c" fill="#848484" />   
                                {/*{dataHuella.map((s, index) => (
                                    <Bar dataKey="value" name={s.name} key={index}
                                    fill={COLORS[index]} />
                                ))}   /*           
                            </BarChart>*/}  

                            <PieChart width={200} height={200} style={{margin:"auto"}}>
                            <Pie
                                data={dataHuellaPie}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                label={renderCustomizedLabel}                                
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                                innerRadius={30}
                                paddingAngle={5}                    
                            >
                                {dataHuellaPie.map((entry, index) => (
                                <Cell label={"black"} key={`cell-${index}`} fill={index == 0 ? "#c1e1fe":index == 1 ? "#c6fcc4":"#e7e7e7"}>
                                <LabelList dataKey="name" position="center" />
                                </Cell>
                                ))}
                            </Pie>
                            </PieChart>
                            <table style={{width: "95%", maxWidth:"600px", marginTop:"30px", margin:"10px auto", textAlign:"center"}}>                        
                                <thead>
                                    <tr>
                                        <th>{t('datapage.tipohuella')}</th>
                                        <th>{t('words.unidad')}</th>
                                        <th>{t('words.porcentaje')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{backgroundColor:'var(--white-color-100)', fontWeight:'bold'}}>{t('datapage.huellahidricatotal')}</td> 
                                        <td>547 l/kg</td>  
                                        <td>100%</td>                                                 
                                    </tr>  
                                    <tr>
                                        <td style={{backgroundColor:'#c1e1fe', textAlign:'left'}}>{t('datapage.huellahidricaazulriosacueducto')}</td> 
                                        <td>0,4 l/kg</td>  
                                        <td>0,1%</td>                                                 
                                    </tr> 
                                    <tr>
                                        <td style={{backgroundColor:'#c6fcc4', textAlign:'left'}}>{t('datapage.huellahidricaverdelluvia')}</td> 
                                        <td>296,5 l/kg</td>  
                                        <td>54,2%</td>                                                 
                                    </tr> 
                                    <tr>
                                        <td style={{backgroundColor:'#e7e7e7', textAlign:'left'}}>{t('datapage.huellahidricagriscontaminacionelsuelo')}</td> 
                                        <td>250,1 l/kg</td>  
                                        <td>45,7%</td>                                                 
                                    </tr>  
                                </tbody>                        
                            </table>
                        </div>

{/*
                        
                        <div style={{display:"flex", justifyContent:"center", flexWrap:"wrap", margin:"10px auto",
                            borderRadius:"20px", width:"80%", boxShadow:"0px 0px 5px 2px var(--black-color-10)"}}>
                            <div style={{width:'100%', display:'flex', backgroundColor:'#c1e1fe'}}>
                                <p style={{width:"95%", margin:"0px auto", color:"#1a93ff", padding:"20px",
                                backgroundColor:"#c1e1fe"}}>Huella hídrica azul (ríos, acueducto):</p>
                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                    width="35pt" height="35pt" viewBox="0 0 641.000000 1280.000000"
                                    preserveAspectRatio="xMidYMid meet" style={{margin:"auto"}}>
                                    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                                    fill="#1a93ff" stroke="none">
                                    <path d="M3055 12293 c-25 -116 -78 -327 -151 -603 -336 -1266 -773 -2505
                                    -1317 -3735 -112 -253 -181 -401 -437 -935 -128 -267 -266 -557 -307 -645
                                    -520 -1114 -734 -1796 -825 -2620 -17 -157 -17 -673 1 -820 91 -777 364 -1387
                                    860 -1920 515 -554 1160 -883 1941 -991 175 -25 625 -25 797 -1 601 84 1096
                                    292 1573 661 134 104 423 390 531 526 359 455 582 987 665 1590 22 158 25 617
                                    6 760 -58 422 -158 754 -347 1148 -224 465 -499 868 -1156 1693 -660 828 -920
                                    1206 -1178 1714 -414 812 -660 1720 -721 2650 -13 206 -13 660 0 857 14 197
                                    45 479 70 626 11 63 18 117 16 119 -2 2 -11 -31 -21 -74z"/>
                                    </g>
                                </svg>
                            </div>
                            <table style={{width: "95%", maxWidth:"600px", marginTop:"30px", margin:"10px auto", textAlign:"center"}}>                        
                                <tbody>
                                <tr>
                                    <td>0,4 l/kg</td>  
                                    <td>0,1%</td>                                                 
                                </tr>                          
                                </tbody>
                            </table>
                        </div>

                        <div style={{display:"flex", justifyContent:"center", flexWrap:"wrap", margin:"10px auto",
                            borderRadius:"20px", width:"80%", boxShadow:"0px 0px 5px 2px var(--black-color-10)"}}>
                            <div style={{width:'100%', display:'flex', backgroundColor:'#c6fcc4'}}>
                                <p style={{width:"95%", margin:"0px auto", color:"#068000", padding:"20px",
                                backgroundColor:"#c6fcc4"}}>Huella hídrica verde (lluvia):</p>
                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                    width="35pt" height="35pt" viewBox="0 0 641.000000 1280.000000"
                                    preserveAspectRatio="xMidYMid meet" style={{margin:"auto"}}>
                                    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                                    fill="#068000" stroke="none">
                                    <path d="M3055 12293 c-25 -116 -78 -327 -151 -603 -336 -1266 -773 -2505
                                    -1317 -3735 -112 -253 -181 -401 -437 -935 -128 -267 -266 -557 -307 -645
                                    -520 -1114 -734 -1796 -825 -2620 -17 -157 -17 -673 1 -820 91 -777 364 -1387
                                    860 -1920 515 -554 1160 -883 1941 -991 175 -25 625 -25 797 -1 601 84 1096
                                    292 1573 661 134 104 423 390 531 526 359 455 582 987 665 1590 22 158 25 617
                                    6 760 -58 422 -158 754 -347 1148 -224 465 -499 868 -1156 1693 -660 828 -920
                                    1206 -1178 1714 -414 812 -660 1720 -721 2650 -13 206 -13 660 0 857 14 197
                                    45 479 70 626 11 63 18 117 16 119 -2 2 -11 -31 -21 -74z"/>
                                    </g>
                                </svg>
                            </div>
                            <table style={{width: "95%", maxWidth:"600px", marginTop:"30px", margin:"10px auto", textAlign:"center"}}>                        
                                <tbody>
                                <tr>
                                    <td>296,5 l/kg</td>  
                                    <td>54,2%</td>                                                 
                                </tr>  
                                </tbody>                        
                            </table>
                        </div>

                        <div style={{display:"flex", justifyContent:"center", flexWrap:"wrap", margin:"10px auto",
                            borderRadius:"20px", width:"80%", boxShadow:"0px 0px 5px 2px var(--black-color-10)"}}>
                            <div style={{width:'100%', display:'flex', backgroundColor:'#e7e7e7'}}>
                                <p style={{width:"95%", margin:"0px auto", color:"#848484", padding:"20px",
                                backgroundColor:"#e7e7e7"}}>Huella hídrica gris (contaminación del suelo):</p>
                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                    width="35pt" height="35pt" viewBox="0 0 641.000000 1280.000000"
                                    preserveAspectRatio="xMidYMid meet" style={{margin:"auto"}}>
                                    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                                    fill="#848484" stroke="none">
                                    <path d="M3055 12293 c-25 -116 -78 -327 -151 -603 -336 -1266 -773 -2505
                                    -1317 -3735 -112 -253 -181 -401 -437 -935 -128 -267 -266 -557 -307 -645
                                    -520 -1114 -734 -1796 -825 -2620 -17 -157 -17 -673 1 -820 91 -777 364 -1387
                                    860 -1920 515 -554 1160 -883 1941 -991 175 -25 625 -25 797 -1 601 84 1096
                                    292 1573 661 134 104 423 390 531 526 359 455 582 987 665 1590 22 158 25 617
                                    6 760 -58 422 -158 754 -347 1148 -224 465 -499 868 -1156 1693 -660 828 -920
                                    1206 -1178 1714 -414 812 -660 1720 -721 2650 -13 206 -13 660 0 857 14 197
                                    45 479 70 626 11 63 18 117 16 119 -2 2 -11 -31 -21 -74z"/>
                                    </g>
                                </svg>
                            </div>
                            <table style={{width: "95%", maxWidth:"600px", marginTop:"30px", margin:"10px auto", textAlign:"center"}}>                        
                                <tbody>
                                <tr>
                                    <td>250,1 l/kg</td>  
                                    <td>45,7%</td>                                                 
                                </tr>  
                                </tbody>                        
                            </table>                            
                        </div>     
                            */}                   
                    </CardContainer>
                </div>

                <CardContainer>
                    <p style={{width:"95%", margin:"10px auto", color:"var(--font-color-green)"}}>{t('datapage.comparacionentreconsumoyofertadeaguaparalafincaproductora')}:</p>
                    {/*
                    <PieChart width={200} height={200} style={{margin:"auto"}}>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    </PieChart>

                    <BarChart
                    width={300}
                    height={200}
                    data={data1}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="cagua" label="Consumo Agua" stackId="a" fill={COLORS[0]} />
                    <Bar dataKey="clluvia" stackId="a" fill={COLORS[1]} />                    
                    </BarChart>*/}

                    <table style={{width: "95%", maxWidth:"600px", marginTop:"30px", margin:"10px auto"}}>
                        <tbody>
                            <tr>
                                <td>{t('datapage.consumodeaguacorrespondealahuellahidricatotal')}:</td> 
                                <td>547 l/kg</td>                                
                            </tr>
                            <tr>
                                <td>{t('datapage.cantidaddelluviaduranteelcrecimientodelaguacate')}:</td>  
                                <td>1110 l/kg</td>   
                            </tr>
                        </tbody>
                    </table>
                </CardContainer>
                
                {/****************************************Huella de carbono */}
                <div style={{width:"calc(100% - 40px)", margin:"0px auto", padding:"20px", 
                        color:"var(--font-color-white)", backgroundColor:"var(--color-green-1)"}}>                        
                </div>
                <p style={{fontSize:'1.2rem', textAlign:'center', fontWeight:'bold'}}>{t('datapage.huelladecarbono')}</p>
                <CardContainer>                    
                    <p style={{width:"95%", margin:"10px auto", color:"var(--font-color-green)"}}>{t('datapage.unaguacatehassparamitigarelcambioclimatico')}</p>
                    <table style={{width: "95%", maxWidth:"600px", marginTop:"30px", margin:"10px auto"}}>
                        <tbody> 
                            <tr>
                                <td>{t('datapage.huelladecarbonodeesteaguacate')}:</td>
                                <td>0,66 {t('datapage.kgdeco2eemitidoporkgdeaguacate')}</td>                                                 
                            </tr>
                            <tr>
                                <td>{t('datapage.absorciondeco2enloscultivos')}:</td>
                                <td>3,40 {t('datapage.kgdeco2eabsorbidoporkgdeaguacate')}</td>                                                     
                            </tr>                           
                        </tbody>
                    </table>
                </CardContainer>

                <div style={{ margin:"auto"}}>
                    <CardContainer>
                        {/*<p style={{width:"calc(100% - 40px)", margin:"0px auto", padding:"20px", 
                        color:"var(--font-color-white)", backgroundColor:"var(--color-gray-1)"}}>
                            Desglose de la huella de carbono de este aguacate:
                </p>*/}

                        <div style={{display:"flex", justifyContent:"center", flexWrap:"wrap", margin:"10px auto",
                            borderRadius:"20px", width:"80%", boxShadow:"0px 0px 5px 2px var(--black-color-10)"}}>
                            <p style={{width:"95%", margin:"0px auto", color:"var(--font-color-black)", padding:"20px",
                            backgroundColor:"var(--white-color-100)", textAlign:"center"}}>{t('datapage.desglosedelahuelladecarbonodeesteaguacate')}:</p>
                            <PieChart width={200} height={200} style={{margin:"auto"}}>
                            <Pie
                                data={dataHuellaCPie}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                label={renderCustomizedLabelC}                                
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                                innerRadius={30}
                                paddingAngle={5}                    
                            >
                                {dataHuellaPie.map((entry, index) => (
                                <Cell label={"black"} key={`cell-${index}`} fill={index == 0 ? "#fdebd8":index == 1 ? "#faeea6":"#38bcba"}>
                                <LabelList dataKey="name" position="center" />
                                </Cell>
                                ))}
                            </Pie>
                            </PieChart>
                            <table style={{width: "95%", maxWidth:"600px", marginTop:"30px", margin:"10px auto", textAlign:"center"}}>                        
                                <thead>
                                    <tr>
                                        <th>{t('datapage.tipohuella')}</th>
                                        <th>{t('words.unidad')}</th>
                                        <th>{t('words.porcentaje')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{backgroundColor:'var(--white-color-100)', fontWeight:'bold'}}>{t('datapage.huelladecarbonototal')}</td> 
                                        <td>660 g CO2e/kg</td>  
                                        <td>100%</td>                                                  
                                    </tr>  
                                    <tr>
                                        <td style={{backgroundColor:'#fdebd8', textAlign:'left'}}>{t('datapage.alcance1emisionesdirectas')}</td> 
                                        <td>170 g CO2e/kg</td>  
                                        <td>26%</td>                                                
                                    </tr> 
                                    <tr>
                                        <td style={{backgroundColor:'#faeea6', textAlign:'left'}}>{t('datapage.alcance2emisionesindirectasyenergia')}</td> 
                                        <td>4 g CO2e/kg</td>  
                                        <td>6%</td>                                                     
                                    </tr> 
                                    <tr>
                                        <td style={{backgroundColor:'#38bcba', textAlign:'left'}}>{t('datapage.alcance3emisionesderivadodeltransporte')}</td> 
                                        <td>450 g CO2e/kg</td>  
                                        <td>68%</td>                                                
                                    </tr>  
                                </tbody>                        
                            </table>
                        </div>
                        {/*
                        <div style={{display:"flex", justifyContent:"center", flexWrap:"wrap", margin:"10px auto",
                            borderRadius:"20px", width:"80%", boxShadow:"0px 0px 5px 2px var(--black-color-10)"}}>
                            <p style={{width:"95%", margin:"0px auto", color:"#fea246", padding:"20px",
                            backgroundColor:"#fdebd8"}}>Alcance 1 - Emisiones directas:</p>
                            <svg fill="#fea246" height="80pt" width="80pt" version="1.1" id="Layer_1"
                                viewBox="0 0 480 480"  style={{margin:"10px auto"}}>
                            <g>
                                <g>
                                    <g>
                                        <path d="M240,0C107.664,0,0,107.664,0,240s107.664,240,240,240s240-107.664,240-240S372.336,0,240,0z M240,460
                                            c-121.309,0-220-98.691-220-220S118.691,20,240,20s220,98.691,220,220S361.309,460,240,460z"/>
                                        <path d="M410,194.999h-27.058c-2.643-8.44-6-16.56-10.03-24.271l19.158-19.158c3.776-3.775,5.854-8.79,5.854-14.121
                                            c0-5.332-2.08-10.347-5.854-14.121l-35.399-35.399c-3.775-3.775-8.79-5.854-14.122-5.854c-5.331,0-10.346,2.079-14.121,5.854
                                            l-19.158,19.158c-7.711-4.03-15.832-7.386-24.271-10.03V70c0-11.028-8.972-20-20-20h-50c-11.028,0-20,8.972-20,20v27.058
                                            c-8.44,2.643-16.56,6-24.271,10.03L151.57,87.93c-3.775-3.776-8.79-5.854-14.121-5.854c-5.332,0-10.347,2.08-14.121,5.854
                                            l-35.399,35.399c-3.775,3.775-5.854,8.79-5.854,14.122c0,5.331,2.079,10.346,5.854,14.121l19.158,19.158
                                            c-4.03,7.711-7.386,15.832-10.03,24.271H70c-11.028,0-20,8.972-20,20v50c0,11.028,8.972,20,20,20h27.057
                                            c2.643,8.44,6,16.56,10.03,24.271L87.929,328.43c-3.776,3.775-5.854,8.79-5.854,14.121c0,5.332,2.08,10.347,5.854,14.121
                                            l35.399,35.399c3.775,3.775,8.79,5.854,14.122,5.854c5.331,0,10.346-2.079,14.121-5.854l19.158-19.158
                                            c7.711,4.03,15.832,7.386,24.271,10.03V410c0,11.028,8.972,20,20,20h50c11.028,0,20-8.972,20.001-20v-27.058
                                            c8.44-2.643,16.56-6,24.271-10.03l19.158,19.158c3.775,3.776,8.79,5.854,14.121,5.854c5.332,0,10.347-2.08,14.121-5.854
                                            l35.399-35.399c3.775-3.775,5.854-8.79,5.854-14.122c0-5.331-2.079-10.346-5.854-14.121l-19.158-19.158
                                            c4.03-7.711,7.386-15.832,10.03-24.271H410c11.028,0,20-8.972,20-20v-50C430,203.971,421.028,194.999,410,194.999z M410,264.998
                                            h-34.598c-4.562,0-8.544,3.086-9.684,7.503c-3.069,11.901-7.716,23.133-13.813,33.387c-2.337,3.931-1.71,8.948,1.524,12.182
                                            l24.5,24.457l-35.357,35.4l-24.5-24.5c-3.236-3.235-8.253-3.86-12.182-1.524c-10.254,6.097-21.487,10.745-33.387,13.813
                                            c-4.417,1.14-7.503,5.122-7.503,9.684V410h-50v-34.599c0-4.562-3.086-8.544-7.503-9.684
                                            c-11.901-3.069-23.133-7.716-33.387-13.813c-1.587-0.944-3.353-1.404-5.107-1.404c-2.586,0-5.147,1.002-7.073,2.931l-24.457,24.5
                                            l-35.4-35.357l24.5-24.5c3.234-3.235,3.861-8.251,1.524-12.182c-6.097-10.254-10.745-21.487-13.813-33.387
                                            c-1.14-4.417-5.122-7.503-9.684-7.503H70v-50h34.596c4.562,0,8.544-3.086,9.684-7.503c3.069-11.901,7.716-23.133,13.813-33.387
                                            c2.337-3.931,1.71-8.948-1.524-12.182l-24.5-24.457l35.357-35.4l24.5,24.5c3.236,3.235,8.253,3.861,12.182,1.524
                                            c10.254-6.097,21.487-10.745,33.387-13.813c4.417-1.14,7.503-5.122,7.503-9.684V70h50v34.596c0,4.562,3.086,8.544,7.503,9.684
                                            c11.901,3.069,23.133,7.716,33.387,13.813c3.929,2.337,8.947,1.709,12.182-1.524l24.457-24.5l35.4,35.357l-24.5,24.5
                                            c-3.234,3.235-3.861,8.251-1.524,12.182c6.097,10.254,10.745,21.487,13.813,33.387c1.14,4.417,5.122,7.503,9.684,7.503H410
                                            V264.998z"/>
                                        <path d="M331.585,292.475l-40-35l-13.17,15.051L298.386,290H240c-27.57,0-50-22.43-50-50h-20c0,38.598,31.402,70,70,70h58.386
                                            l-19.971,17.475l13.17,15.051l40-35c2.17-1.898,3.415-4.642,3.415-7.525S333.755,294.373,331.585,292.475z"/>
                                        <path d="M201.585,207.473L181.614,190H240c27.57,0,50,22.43,50,50h20c0-38.598-31.402-70-70-70h-58.386l19.971-17.475
                                            l-13.17-15.051l-40,35c-2.17,1.898-3.415,4.642-3.415,7.525s1.245,5.627,3.415,7.525l40,35L201.585,207.473z"/>
                                    </g>
                                </g>
                            </g>
                            </svg>
                            <table style={{width: "95%", maxWidth:"600px", marginTop:"30px", margin:"10px auto", textAlign:"center"}}>                                
                                <tbody>
                                <tr>
                                    <td>170 g CO2e/kg</td>  
                                    <td>26%</td>                                                 
                                </tr>                          
                                </tbody>
                            </table>
                        </div>

                        <div style={{display:"flex", justifyContent:"center", flexWrap:"wrap", margin:"10px auto",
                            borderRadius:"20px", width:"80%", boxShadow:"0px 0px 5px 2px var(--black-color-10)"}}>
                            <p style={{width:"95%", margin:"0px auto", color:"#dabb03", padding:"20px",
                            backgroundColor:"#faeea6"}}>Alcance 2 - Emisiones indirectas & energía:</p>
                            <svg height="80pt" width="80pt"  fill="#dabb03" version="1.1" id="Capa_1" 
                                viewBox="0 0 59.831 59.831" style={{margin:"10px auto"}}>
                            <g>
                                <path d="M50.651,10.354c0.12-0.86-0.858-1.239-1.187-1.364L34.677,3.347
                                    c-0.749-0.285-1.852-1.082-2.356-1.705l-0.632-0.778C31.235,0.307,30.623,0,29.967,0c-0.688,0-1.335,0.348-1.778,0.95l-0.336,0.457
                                    c-0.468,0.637-1.523,1.444-2.261,1.728L10.366,8.986C10.04,9.113,9.065,9.493,9.182,10.35c-0.001,0.025-0.015,0.046-0.015,0.072
                                    v5.692c0,0.481,0.389,0.869,0.87,0.869s0.869-0.389,0.869-0.869v-4.823h13.485l-8.412,47.52c-0.084,0.473,0.232,0.924,0.706,1.006
                                    c0.47,0.087,0.922-0.231,1.006-0.703l2.003-11.312h20.537l2.265,11.331c0.084,0.412,0.446,0.698,0.852,0.698
                                    c0.057,0,0.114-0.005,0.171-0.018c0.471-0.094,0.777-0.551,0.684-1.021l-9.489-47.501h14.211v4.823c0,0.481,0.39,0.869,0.869,0.869
                                    c0.482,0,0.871-0.389,0.871-0.869v-5.692C50.666,10.397,50.653,10.377,50.651,10.354z M23.59,25.801h4.238l-5.432,6.736
                                    L23.59,25.801z M22.769,34.847l6.891-8.548l7.181,8.548H22.769z M34.925,36.585l-5.21,3.049l-5.437-3.049H34.925z M21.593,37.073
                                    l6.383,3.578l-7.825,4.578L21.593,37.073z M22.174,46.061l7.559-4.424l7.888,4.424H22.174z M39.723,45.247l-8.251-4.627
                                    l6.561-3.839L39.723,45.247z M37.19,32.56l-5.679-6.758h4.328L37.19,32.56z M35.492,24.062H23.897l2.144-12.112h7.032
                                    L35.492,24.062z M13.74,9.553l12.475-4.796c1.043-0.4,2.379-1.421,3.04-2.321L29.59,1.98c0.225-0.308,0.512-0.309,0.75-0.018
                                    l0.632,0.777c0.698,0.859,2.055,1.84,3.086,2.233l12.006,4.581H13.74z"/>
                            </g>
                            </svg>
                            <table style={{width: "95%", maxWidth:"600px", marginTop:"30px", margin:"10px auto", textAlign:"center"}}>                        
                                <tbody>
                                <tr>
                                    <td>4 g CO2e/kg</td>  
                                    <td>6%</td>                                                 
                                </tr>  
                                </tbody>                        
                            </table>
                        </div>

                        <div style={{display:"flex", justifyContent:"center", flexWrap:"wrap", margin:"10px auto",
                            borderRadius:"20px", width:"80%", boxShadow:"0px 0px 5px 2px var(--black-color-10)"}}>
                            <p style={{width:"95%", margin:"0px auto", color:"#38bcba", padding:"20px",
                            backgroundColor:"#bafcfb"}}>Alcance 3 - Emisiones derivado del transporte:</p>
                            <svg fill="#38bcba" width="80pt" height="80pt" viewBox="0 0 24 24"  style={{margin:"10px auto"}}>
                                <path d="M3.62,17.28a1,1,0,0,0,1.86-.74L4.36,13.72,11,12.25V17a1,1,0,0,0,2,0V12.25l6.64,1.47-1.12,2.82a1,1,0,0,0,.56,1.3,1,1,0,0,0,.37.07,1,1,0,0,0,.93-.63l1.55-3.91a1,1,0,0,0-.05-.84,1,1,0,0,0-.66-.51L18,11.31V7a1,1,0,0,0-1-1H15V3a1,1,0,0,0-1-1H10A1,1,0,0,0,9,3V6H7A1,1,0,0,0,6,7v4.31L2.78,12a1,1,0,0,0-.66.51,1,1,0,0,0-.05.84ZM11,4h2V6H11ZM8,8h8v2.86L12.22,10h-.1L12,10l-.12,0h-.1L8,10.86ZM20.71,19.28a4.38,4.38,0,0,0-1,.45,2.08,2.08,0,0,1-2.1,0,4.62,4.62,0,0,0-4.54,0,2.14,2.14,0,0,1-2.12,0,4.64,4.64,0,0,0-4.54,0,2.08,2.08,0,0,1-2.1,0,4.38,4.38,0,0,0-1-.45A1,1,0,0,0,2,20a1,1,0,0,0,.67,1.24,2.1,2.1,0,0,1,.57.25,4,4,0,0,0,2,.55,4.14,4.14,0,0,0,2.08-.56,2.65,2.65,0,0,1,2.56,0,4.15,4.15,0,0,0,4.12,0,2.65,2.65,0,0,1,2.56,0,4,4,0,0,0,4.1,0,2.1,2.1,0,0,1,.57-.25A1,1,0,0,0,22,20,1,1,0,0,0,20.71,19.28Z"/>
                            </svg>
                            <table style={{width: "95%", maxWidth:"600px", marginTop:"30px", margin:"10px auto", textAlign:"center"}}>                        
                                <tbody>
                                <tr>
                                    <td>450 g CO2e/kg</td>  
                                    <td>68%</td>                                                 
                                </tr>  
                                </tbody>                        
                            </table>
                        </div>

                        <div style={{display:"flex", justifyContent:"center", flexWrap:"wrap", margin:"10px auto",
                            borderRadius:"20px", width:"80%", boxShadow:"0px 0px 5px 2px var(--black-color-10)"}}>
                            <p style={{width:"95%", margin:"0px auto", color:"var(--font-color-white)", padding:"20px",
                            backgroundColor:"var(--color-gray-1)", textAlign:"center"}}>Huella de carbono total:</p>
                            <table style={{width: "95%", maxWidth:"600px", marginTop:"30px", margin:"10px auto", textAlign:"center"}}>                        
                                <tbody>
                                <tr>
                                    <td>660 g CO2e/kg</td>  
                                    <td>100%</td>                                                 
                                </tr>  
                                </tbody>                        
                            </table>
                            </div>*/}
                    </CardContainer>
                </div>                
            </div>
            :
            <div style={{width:"100%", display:"flex", flexDirection:"column", 
            height:window.innerHeight}}>
                <LanguageMenu />
                <FormBatch 
                company="Power by Ibisa Group"
                logo={LogoIbisa}
                message={`${t('frontpage.bienvenidoalportalinformativodelaguacatehassdejardinexotics')}<br/>
                ${t('frontpage.elaguacatehassdejardinexoticsresponsableconelmedioambiente')}`}
                onClick={OnClickButtonBatch}/>
            </div>
        }
        </>
    );
}