import React from "react";

export default function HeaderBar({children}){
    return(
        <header style={{
            backgroundColor:"var(--header-menu-background)",
            height:"max-content",
            boxShadow:"0px 1px 3px 0px var(--black-color-10)",
            display:"grid",
            alignContent:"center",
            gridTemplateColumns: '50px 1fr',
            gridTemplateRows: '50px max-content'
        }}>
            {children}
        </header>
    );
}