import React from "react";

export default function CardContainer({children}) {
    return(
        <div style={{margin:"20px 5px", 
            border:"1px solid var(--black-color-10)",
            boxShadow: "0px 0px 3px 3px var(--black-color-10)"}}>
            {children}
        </div>
    );
}