import { t } from "i18next";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";


export default function FormBatch({message, logo, company, onClick}) {

    const inputBatch = useRef();

    const { t } = useTranslation();

    function OnClickButtonAcept(e) {
        e.preventDefault();
        onClick(inputBatch.current.value);
    }

    return(
        <div style={{
            border:"1px solid var(--black-color-10)",
            boxShadow:"0px 0px 5px 0px var(--black-color-10)",
            borderRadius:"10px",
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            width: window.innerWidth > 600 ? "50%":"80%",           
            margin:"auto",
            padding:"20px"
        }}>
            <div style={{ width:"auto", display:"contents", alignItems:"center"}}>
                <img src={logo}  style={{maxHeight:"100px"}}/>                 
            </div>
            <p style={{width:"90%", margin:"20px 0px 0px 0px",
            color:"var(--black-color-70)"}} dangerouslySetInnerHTML={{__html: message}}></p>
            <input ref={inputBatch} type="text" placeholder={t('frontpage.ingreseelcodigodellotedeproduccion')} style={{
                height:"35px", margin:"50px 0px 20px 0px",
                borderRadius:"5px",
                border:"none",
                backgroundColor:"var(--black-color-10)",
                padding:"5px",
                width:"90%"
            }}/>
            <button className="dark" onClick={(e)=>OnClickButtonAcept(e)}>{t('words.aceptar')}</button>
            
            <p style={{margin:"20px 0px 0px 0px", fontSize:"0.6rem"}}>{company}</p>
        </div>
    );
}