import es from './langs/es.json';
import en from './langs/en.json';
import de from './langs/de.json';
import fr from './langs/fr.json';

const langs = {
  es: {
    translation: es
  },
  en: {
    translation: en
  },
  de: {
    translation: de
  },
  fr: {
    translation: fr
  }
}

export { langs };