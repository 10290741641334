import React, { useContext } from "react";

//Assets
import IconEsp from "../img/icon_esp.png";
import IconEng from "../img/icon_eng.png";
import IconDeu from "../img/icon_deu.png";
import IconFr from "../img/icon_fr.png";
import { LanguageContext } from "../lang/languageContext";

export default function LanguageMenu() {

    const { setSelectedLanguage } = useContext(LanguageContext);

    return(
        <nav style={{position:"absolute", display:"flex"}}>
            <ul style={{
                display:"flex",
                alignItems:"center",
                width: "100%", /* window.innerWidth > 600 ? "50%":"90%",*/
                margin:"20px auto",
                height:"30px",
                justifyContent:"center",
                padding:"0px",
            }}>
                <li
                    style={{listStyle:"none", width:"40px", cursor:"pointer", margin:"5px"}}
                    onClick={() => setSelectedLanguage('es')}
                ><img width="100%" src={IconEsp}/></li>
                <li
                    style={{listStyle:"none", width:"40px", cursor:"pointer", margin:"5px"}}
                    onClick={() => setSelectedLanguage('en')}
                ><img width="100%" src={IconEng}/></li>
                <li
                    style={{listStyle:"none", width:"40px", cursor:"pointer", margin:"5px"}}
                    onClick={() => setSelectedLanguage('de')}
                ><img width="100%" src={IconDeu}/></li>
                <li
                    style={{listStyle:"none", width:"40px", cursor:"pointer", margin:"5px"}}
                    onClick={() => {setSelectedLanguage('fr')}}
                ><img width="100%" src={IconFr}/></li>
            </ul>
        </nav>
    );
};